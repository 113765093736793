import React, { useState } from 'react'
import axios from 'axios'
import { BASE_URL } from '../../config'
import '../../styles/Terms.css'
import { toast } from 'react-toastify'
// import '../styles/Terms.css'

export default function SearchAppify() {
    const [password, setPassword] = useState('');
    const [searchText, setSearchText] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [isLogin, setIsLogin] = useState(false);
    const [submitLabel, setSubmitLabel] = useState('Search');
    const [isSearching, setIsSearching] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.post(`${BASE_URL}/appify/login`, { password })
            if (response.data.status === 1) {
                toast.success(response.data.message);
                setIsLogin(true)
            } else {
                toast.error(response.data.message);
            }
        } catch (err) {
            toast.error('Failed to sign in');
        }
    }

    const handleSubmitDocument = async (e) => {
        e.preventDefault()
        try {
            setIsSearching(true);
            setSubmitLabel('Searching...');
            if (searchText) {
                const searchResponse = await axios.get(
                    `${BASE_URL}/appify/search?keyword=${searchText}`
                );
                if (searchResponse.status) {
                    setSearchResult(searchResponse.data.data);
                }
                else {
                    toast.error(searchResponse.message);
                }
                setSubmitLabel('Search');
                setIsSearching(false);
            }
            else {
                setSubmitLabel('Search');
                setIsSearching(false);
                toast.error('Search text is required');
            }
        } catch (err) {
            console.log(err);
            setSubmitLabel('Search');
            setIsSearching(false);
            toast.error('Try again');
        }
    }

    return (
        <>
            {isLogin ? (
                <div
                    className="terms-container"
                    style={{ marginLeft: '-400px', width: '1240px' }}
                >
                    <div className="row">
                        <div className="card ">
                            <h2 className="card-title" style={{ marginTop: "10px", marginBottom: "10px" }}>Search Appify</h2>
                            <div className="col-md-12 d-flex1 1justify-content-center">
                                {/* <div className='col-4'> */}

                                <div className="card-body">
                                    <form onSubmit={handleSubmitDocument}>
                                        <div className="form-group float-start" style={{ width: '89%' }}>
                                            <input
                                                type="text"
                                                value={searchText}
                                                onChange={(e) => setSearchText(e.target.value)}
                                                required
                                                placeholder="Enter search text here"
                                            />
                                        </div>
                                        <div className='float-end'>
                                            <button type="submit" className="m-0 btn btn-dark btn-sm1" disabled={submitLabel === 'Searching...' ? true : false}>
                                                {submitLabel}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                {/* </div> */}
                            </div>
                        </div>

                        {isSearching ? (
                            searchResult.length === 0 ? (
                                <div className="col-12 text-center">Not found</div>
                            ) : (
                                null
                            )
                        ) : searchResult.length > 0 ? (
                            <div className="card mt-3">
                                <div className="card-body">
                                    <h2 className="card-title p-0 m-0">Result</h2>
                                    <div className="table-responsive">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Filename</th>
                                                    <th>Search result</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {searchResult.map((app, index) => (
                                                    <tr key={index}>
                                                        <td>{app.filename}</td>
                                                        <td>{app.answer ? app.answer : 'N/A'}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : (
                <div className="ac pt-0 text-center">
                    <div className='logo_wrps'>
                    </div>
                    <h2 className="login-heading">Log in</h2>
                    <form onSubmit={handleLogin}>
                        <div className="form-group mb-2">
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                placeholder="Enter password"
                            />
                        </div>
                        <div>
                            <button type="submit" className="auth-btn m-0">
                                Log in
                            </button>
                        </div>
                    </form>
                </div>
            )
            }
        </>

    )
}
