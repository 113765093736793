import React, { useContext, useState, useEffect } from 'react'
import './styles/App.css'
import logo from './img/logo-svg.png'
import qaLogo from './img/logo.png'
import { Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom'
import Home from './components/Home'
import NewRequest from './components/NewRequest'
import QaAdminProfile from './components/QaAdmin/QaAdminProfile'
import CompletedAnalyses from './components/CompletedAnalyses'
import WorkSpace from './components/WorkSpace'
import HomePage from './components/User/UserHome'
import LegalConciergeRep from './components/LegalConciergeRep'
import DocumentViewer from './components/DocumentViewer'
import AuthContext, { AuthProvider } from './AuthContext'
import QAFetchContext, { QAFetchProvider } from './QaFetchContext'
import Task from './components/Task'
import Discover from './components/Discover'
import Reports from './components/Reports'
import AdminCompletedAnalysis from './components/AdminCompletedAnalysis'
import logoutLogo from './img/logout.png'
import Accounts from './components/Accounts'
import Invite from './components/Invite'
import ErrorBoundary from './components/ErrorBoundary'
import QaAdminTools from './components/QaAdmin/QaAdminTools'
import Auth from './components/Auth'
import TermsAndConditions from './components/Terms'
import PrivacyPolicy from './components/Privacy'
import Documents from './components/User/Documents'
import Settings from './components/User/Settings'
import SlackConnect from './components/User/SlackConnect'
import companyLogoDefault from './img/organization.png'
import chevronDownIcon from './img/down-chevron.png'
import chevronUpIcon from './img/up-chevron.png'
import SendDocument from './components/SendDocument'
import EnterPassword from './components/Enterpassword'
import ReadLine from './components/User/ReadLine'
import Request from './components/Request'
import Repository from './components/Repository'
import ForgotPassword from './components/ForgotPassword'
import Risk from './components/Risk'
import axios from 'axios'
import { BASE_URL } from './config'
import DiscoverDetails from './components/DiscoverDetails'
import Procurement from './components/Procurement'
import Extract from './Extract'
import Tools from './components/User/Tools'
import Clauses from './components/Clauses'
import WorkFlow from './components/WorkFlow'
import RedlineDocument from './components/Redline/RedlineDocument'
import SantiStensul from './components/SantiStensul/SantiStensulDocument'
import SantiStensulCNew from './components/SantiStensulCNew/SantiStensulDocument'
import SearchAppify from './components/Appify/SearchAppify'
import Athena from './components/Athena/SearchAthena'

function AppContent() {
  const navigate = useNavigate()
  const location = useLocation() // Hook to get current route
  const { isAuthenticated, logout, userRole, userToken, userEmail } =
    useContext(AuthContext)
  const [selectedTab, setSelectedTab] = useState('Home')
  const [newCompanyName, setNewCompanyName] = useState('')
  const [newCompanyLogo, setNewCompanyLogo] = useState(companyLogoDefault)
  const [error, setError] = useState('')
  const [showLogout, setShowLogout] = useState(false)
  const [isUserLink, setIsUserLink] = useState(false)

  // Expand/collapse state
  const [isAutomationOpen, setIsAutomationOpen] = useState(false)
  const [isOprOpen, setIsOprOpen] = useState(false)
  const [isProcurementOpen, setIsProcurementOpen] = useState(false)

  // for page session tracking
  const [duration, setDuration] = useState(0)

  useEffect(() => {
    const savedTab = localStorage.getItem('selectedTab')
    if (savedTab) {
      setSelectedTab(savedTab)
    }
    setIsUserLink(window.location.pathname.includes('user-link'))
  }, [])

  // Update selected tab based on the current location pathname
  useEffect(() => {
    const pathToTabMap = {
      '/': 'Home',
      '/home': 'Home',
      '/user-home': 'Home',
      '/extract': 'Extract',
      '/accounts': 'Accounts',
      '/documents': 'Documents',
      '/settings': 'Settings',
      '/redline': 'RedLine',
      '/risk': 'Risk',
      '/reports': 'Reports',
      '/discover': 'discover',
      '/request': 'request',
      '/procurement': 'procurement',
      '/automation': 'Automation',
      '/tools': 'tools',
      '/invite': 'Settings',
      '/repository': 'Repository',
      '/workflow': 'WorkFlow',
    }
    const currentTab = pathToTabMap[location.pathname] || 'Home'
    setSelectedTab(currentTab)
  }, [location.pathname])

  // Timer for usage analytics
  useEffect(() => {
    const intervalId = setInterval(() => {
      setDuration((prevCount) => prevCount + 1)
    }, 1000)
    return () => clearInterval(intervalId)
  }, [])

  // Save session analytics on route change
  useEffect(() => {
    const interval = setInterval(() => {
      savesSession()
    }, 120000)
    return () => {
      clearInterval(interval)
      savesSession()
    }
  }, [location.pathname])

  const savesSession = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/analytics/save`, {
        duration: duration,
        page: location.pathname,
        token: userToken,
      })
      if (response.data.status === 1) {
        setDuration(0)
      }
    } catch (error) {
      console.error('Error creating user:', error)
    }
  }

  // Toggles
  const toggleAutomation = () => setIsAutomationOpen(!isAutomationOpen)
  const toggleOpr = () => setIsOprOpen(!isOprOpen)
  const toggleProcurement = () => setIsProcurementOpen(!isProcurementOpen)
  const toggleLogoutMenu = () => setShowLogout(!showLogout)

  const handleTabChange = (tab) => {
    setSelectedTab(tab)
    localStorage.setItem('selectedTab', tab)
  }

  // fetch user details if role is user
  useEffect(() => {
    if (userRole === 'user') {
      const fetchUserDetails = async () => {
        try {
          const response = await axios.post(
            `${BASE_URL}/${userRole}/getUser`,
            { userEmail },
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
                'Content-Type': 'application/json',
              },
            }
          )
          if (response.data.status === 1) {
            setNewCompanyLogo(response.data.url)
            setNewCompanyName(response.data.data.company_name)
          } else {
            setError(response.data.message)
          }
        } catch (error) {
          setError('Failed to fetch user details')
        }
      }
      fetchUserDetails()
    }
  }, [userToken, userEmail, userRole])

  // If not authenticated and not userLink => show Auth screen
  if (!isAuthenticated && !isUserLink) {
    return (
      <div className="auth-wrapper">
        <div className="auth-container">
          <Routes>
            <Route path="/" element={<Auth />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/redline-document" element={<RedlineDocument />} />
            <Route path="/redline/santi-stensul" element={<SantiStensul />} />
            <Route path="/redline/santi-stensul/c-new" element={<SantiStensulCNew />} />
            <Route path="/search-appify" element={<SearchAppify />} />
            <Route path="/athena" element={<Athena />} />
            
            <Route path="" element={<Auth />} />
          </Routes>
        </div>
      </div>
    )
  }

  // If not authenticated but is a userLink => show Document Send
  if (!isAuthenticated && isUserLink) {
    return (
      <div className="app">
        <div className="container">
          <Routes>
            <Route path="/user-link/:token" element={<SendDocument />} />
          </Routes>
        </div>
      </div>
    )
  }

  const renderSidebar = () => {
    switch (userRole) {
      case 'qa':
      case 'qa-analyst':
        return (
          <>
            <Link to="/home">
              <button id="Home" onClick={() => handleTabChange('Home')}>
                Home
              </button>
            </Link>
            <Link to="/workspace">
              <button id="WorkSpace">WorkSpace</button>
            </Link>
          </>
        )
      case 'qa-admin':
        return (
          <>
            <Link to="/home">
              <button id="Home" onClick={() => handleTabChange('Home')}>
                Home
              </button>
            </Link>
            <Link to="/workspace">
              <button
                id="WorkSpace"
                onClick={() => handleTabChange('WorkSpace')}
              >
                WorkSpace
              </button>
            </Link>
            <Link to="/completed">
              <button id="Completed">Completed</button>
            </Link>
            <Link to="/qaadmin-tools">
              <button
                id="AdminTools"
                onClick={() => handleTabChange('AdminTools')}
              >
                Admin Tools
              </button>
            </Link>
            <Link to="/qaadmin-profile">
              <button id="Profile" onClick={() => handleTabChange('Profile')}>
                Profile
              </button>
            </Link>
          </>
        )
      default: // general "user" role
        return (
          <>
            <Link to="/user-home">
              <button
                id="Home"
                className={selectedTab === 'Home' ? 'active' : ''}
                onClick={() => handleTabChange('Home')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-home"
                >
                  <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                  <polyline points="9 22 9 12 15 12 15 22"></polyline>
                </svg>
                &nbsp; Home
              </button>
            </Link>

            <Link to="/redline">
              <button
                id="Redline"
                className={selectedTab === 'RedLine' ? 'active' : ''}
                onClick={() => handleTabChange('RedLine')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-zap"
                >
                  <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon>
                </svg>
                &nbsp; Negotiation
              </button>
            </Link>

            <Link to="/documents">
              <button
                id="UserDocuments"
                onClick={() => handleTabChange('Documents')}
                className={selectedTab === 'Documents' ? 'active' : ''}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-file-text"
                >
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                  <polyline points="14 2 14 8 20 8"></polyline>
                  <line x1="16" y1="13" x2="8" y2="13"></line>
                  <line x1="16" y1="17" x2="8" y2="17"></line>
                  <polyline points="10 9 9 9 8 9"></polyline>
                </svg>
                &nbsp; Vault
              </button>
            </Link>
            <Link to="/settings">
              <button
                id="Settings"
                className={selectedTab === 'Settings' ? 'active' : ''}
                onClick={() => handleTabChange('Settings')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-settings"
                >
                  <circle cx="12" cy="12" r="3"></circle>
                  <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                </svg>
                &nbsp; Settings
              </button>
            </Link>
            <Link to="/tools">
              <button
                id="Tools"
                className={selectedTab === 'Tools' ? 'active' : ''}
                onClick={() => handleTabChange('Tools')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-tool"
                >
                  <path d="M14.7 13.3a6 6 0 1 1-1.4-1.4l6 6a1 1 0 0 1-1.4 1.4z"></path>
                </svg>
                &nbsp; Tools
              </button>
            </Link>

            {/* "Automation" Parent Accordion */}
            <div className="w-100 pad-20px">
              <div className="accordion accordion-flush" id="accordionAutomation">
                <div className="accordion-item border-radius-10">
                  <h2 className="accordion-header mt-0">
                    <button
                      onClick={() => {
                        toggleAutomation()
                        handleTabChange('Automation')
                      }}
                      className={
                        selectedTab === 'Automation'
                          ? 'accordion-button proc_btns_drp m-0 active'
                          : 'accordion-button proc_btns_drp m-0'
                      }
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseAutomation"
                      aria-expanded={isAutomationOpen}
                      aria-controls="flush-collapseAutomation"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-cpu"
                      >
                        <rect
                          x="4"
                          y="4"
                          width="16"
                          height="16"
                          rx="2"
                          ry="2"
                        ></rect>
                        <rect x="9" y="9" width="6" height="6"></rect>
                        <line x1="9" y1="1" x2="9" y2="4"></line>
                        <line x1="15" y1="1" x2="15" y2="4"></line>
                        <line x1="9" y1="20" x2="9" y2="23"></line>
                        <line x1="15" y1="20" x2="15" y2="23"></line>
                        <line x1="20" y1="9" x2="23" y2="9"></line>
                        <line x1="20" y1="14" x2="23" y2="14"></line>
                        <line x1="1" y1="9" x2="4" y2="9"></line>
                        <line x1="1" y1="14" x2="4" y2="14"></line>
                      </svg>
                      &nbsp; Automation &nbsp;&nbsp;
                      <div className="drop_open_click">
                        {/* Toggle Icon: Up/Down */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-chevron-down"
                          style={{
                            transform: isAutomationOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.3s ease',
                          }}
                        >
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </div>
                    </button>
                  </h2>

                  <div
                    id="flush-collapseAutomation"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionAutomation"
                  >
                    <div className="accordion-body pad-30px" style={{ marginTop: '-50px' }}>
                      {/* Procurement Menu */}
                      <div className="accordion accordion-flush" id="accordionProcurement">
                        <div className="accordion-item border-radius-10">
                          <h2 className="accordion-header">
                            <button
                              onClick={() => {
                                toggleProcurement()
                                handleTabChange('Procurement')
                                navigate('/procurement')
                              }}
                              className={
                                selectedTab === 'Procurement'
                                  ? 'accordion-button proc_btns_drp m-0 active'
                                  : 'accordion-button proc_btns_drp m-0'
                              }
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseProcurement"
                              aria-expanded={isProcurementOpen}
                              aria-controls="flush-collapseProcurement"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-shopping-cart"
                              >
                                <circle cx="9" cy="21" r="1"></circle>
                                <circle cx="20" cy="21" r="1"></circle>
                                <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                              </svg>
                              &nbsp; Procurement &nbsp;&nbsp;
                              <div className="drop_open_click">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-chevron-down"
                                  style={{
                                    transform: isProcurementOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: 'transform 0.3s ease',
                                  }}
                                >
                                  <polyline points="6 9 12 15 18 9"></polyline>
                                </svg>
                              </div>
                            </button>
                          </h2>

                          <div
                            id="flush-collapseProcurement"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionProcurement"
                          >
                            <div className="accordion-body p-0">
                              <span
                                style={{ cursor: 'pointer' }}
                                onClick={() => navigate('/discover')}
                                className="disc_tab_btn"
                              >
                                Discover
                              </span>
                            </div>
                            <div className="accordion-body p-0">
                              <span
                                style={{ cursor: 'pointer' }}
                                onClick={() => navigate('/request')}
                                className="disc_tab_btn"
                              >
                                Request
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End Procurement Menu */}

                      {/* Operations Menu, same level as Procurement */}
                      <div className="accordion accordion-flush mt-3" id="accordionOpr">
                        <div className="accordion-item border-radius-10">
                          <h2 className="accordion-header mt-0">
                            <button
                              onClick={() => {
                                toggleOpr()
                                handleTabChange('Operations')
                              }}
                              className={
                                selectedTab === 'Operations'
                                  ? 'accordion-button proc_btns_drp m-0 active'
                                  : 'accordion-button proc_btns_drp m-0'
                              }
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseOpr"
                              aria-expanded={isOprOpen}
                              aria-controls="flush-collapseOpr"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="feather feather-pie-chart"
                              >
                                <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
                                <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                              </svg>
                              &nbsp; Operations &nbsp;&nbsp;
                              <div className="drop_open_click">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-chevron-down"
                                  style={{
                                    transform: isOprOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: 'transform 0.3s ease',
                                  }}
                                >
                                  <polyline points="6 9 12 15 18 9"></polyline>
                                </svg>
                              </div>
                            </button>
                          </h2>

                          <div
                            id="flush-collapseOpr"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionOpr"
                          >
                            <div className="accordion-body pad-30px pt-0">
                              <ul className="oprectionul">
                                <li>
                                  <Link to="/repository">Repository</Link>
                                </li>
                                <li>
                                  <Link to="/workflow">Work Flow</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End Operations Menu */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Link to="/extract">
              <button
                id="Reports"
                className={selectedTab === 'Extract' ? 'active' : ''}
                onClick={() => handleTabChange('Reports')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-shuffle"
                >
                  <polyline points="16 3 21 3 21 8"></polyline>
                  <line x1="4" y1="20" x2="21" y2="3"></line>
                  <polyline points="21 16 21 21 16 21"></polyline>
                  <line x1="15" y1="15" x2="21" y2="21"></line>
                  <line x1="4" y1="4" x2="9" y2="9"></line>
                </svg>
                &nbsp; Extract
              </button>
            </Link>
          </>
        )
    }
  }

  return (
    <div className="App">
      {/* If not userLink, show sidebar */}
      {!isUserLink && (
        <div>
          {userRole === 'user' ? (
            <div className="sidenav user">
              <div className="logo-class user">
                <div className="logo_wrps mrgbtm"></div>
              </div>
              {renderSidebar()}
              {/* Company + Logout */}
              <div className="logout-button user">
                <div className="company-info" onClick={toggleLogoutMenu}>
                  <img
                    className="company-logo mx-0"
                    src={newCompanyLogo || companyLogoDefault}
                    alt="Company Logo"
                    style={{ width: '30px', height: '30px' }}
                  />
                  <div className="company-name mx-2">
                    {newCompanyName || 'Company Name'}
                  </div>
                  <img
                    className="down-icons mx-0"
                    src={showLogout ? chevronUpIcon : chevronDownIcon}
                    alt="chevron"
                  />
                </div>
                {showLogout && (
                  <div className="submenu">
                    <button className="logout-button user" onClick={logout}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-log-out"
                      >
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                        <polyline points="16 17 21 12 16 7"></polyline>
                        <line x1="21" y1="12" x2="9" y2="12"></line>
                      </svg>{' '}
                      &nbsp; Log Out
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="sidenav">
              <div className="logo-class">
                <img
                  className="logo-image"
                  src={qaLogo}
                  alt="modInsight Logo"
                />
              </div>
              {renderSidebar()}
              <button className="logout-button" onClick={logout}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-log-out"
                >
                  <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                  <polyline points="16 17 21 12 16 7"></polyline>
                  <line x1="21" y1="12" x2="9" y2="12"></line>
                </svg>{' '}
                &nbsp; Log Out
              </button>
            </div>
          )}
        </div>
      )}

      <div className="content" style={{ marginLeft: isUserLink ? '0' : '220' }}>
        <Routes>
          {userRole === 'user' ? (
            <Route path="/" element={<HomePage />} />
          ) : (
            <Route path="/" element={<Home />} />
          )}
          <Route path="/home" element={<Home />} />
          <Route path="/user-home" element={<HomePage />} />
          <Route path="/new-request" element={<NewRequest />} />
          <Route path="/tasks" element={<Task />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/extract" element={<Extract />} />
          <Route path="/repository" element={<Repository />} />
          <Route path="/discover" element={<Discover />} />
          <Route path="/procurement" element={<Procurement />} />
          <Route path="/discover-details" element={<DiscoverDetails />} />
          <Route path="/accounts" element={<Accounts />} />
          <Route path="/invite" element={<Invite />} />
          <Route path="/documents" element={<Documents />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/qaadmin-profile" element={<QaAdminProfile />} />
          <Route path="/qaadmin-tools" element={<QaAdminTools />} />
          <Route path="/qaadmin-profile" element={<QaAdminProfile />} />
          <Route path="/redline" element={<ReadLine />} />
          <Route path="/request" element={<Request />} />
          <Route path="/workflow" element={<WorkFlow />} />
          <Route path="/qaadmin-profile" element={<QaAdminProfile />} />
          <Route path="/qaadmin-tools" element={<QaAdminTools />} />
          <Route path="/completed" element={<AdminCompletedAnalysis />} />
          <Route path="/completed-analyses" element={<CompletedAnalyses />} />
          <Route path="/workspace" element={<WorkSpace />} />
          <Route path="/legal-concierge-rep" element={<LegalConciergeRep />} />
          <Route path="/document-viewer/:fileId" element={<DocumentViewer />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/user-link/:token" element={<SendDocument />} />
          <Route path="/enterpassword" element={<EnterPassword />} />
          <Route path="/slack-connect" element={<SlackConnect />} />
          <Route path="/risk" element={<Risk />} />
          <Route path="/tools" element={<Tools />} />
          <Route path="/clauses" element={<Clauses />} />
          {/* <Route path="/redline-document" element={<RedlineDocument />} /> */}
          
          
        </Routes>
      </div>
    </div>
  )
}

function App() {
  return (
    <QAFetchProvider>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </QAFetchProvider>
  )
}

export default App