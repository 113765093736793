import React, { useRef, useState } from 'react'
import axios from 'axios'
import { BASE_URL } from '../../config'
import '../../styles/Terms.css'
import { toast } from 'react-toastify'
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
// import '../styles/Terms.css'
import Mammoth from "mammoth";
import { renderAsync } from "docx-preview";
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.mjs`;

export default function SearchAthena() {
    const currentSearch = localStorage.getItem("currentSearch");
      const [docxContent, setDocxContent] = useState("");
      const predefinedKeywords = [currentSearch]; // Add keywords to highlight
    
      const containerRef = useRef(null);
      const [loading, setLoading] = useState(true);
    const [password, setPassword] = useState('');
    const [searchText, setSearchText] = useState('');
    const [selectedFile, setSelectedFile] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [isLogin, setIsLogin] = useState(false);
    const [submitLabel, setSubmitLabel] = useState('Search');
    const [isSearching, setIsSearching] = useState(false);
    const [token, setToken] = useState('');
    const [filepath, setFilepath] = useState('');
    const [showFileOpenerView, setshowFileOpenerView] = useState(false)
    const [documentUrl, setDocumentUrl] = useState("");
      const [numPages, setNumPages] = useState(null);
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
      };
    const handleLogin = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.post(`${BASE_URL}/athena/login`, { password })
            if (response.data.status === 1) {
                setToken(response.data.data.token);
                toast.success(response.data.message);
                setIsLogin(true)
            } else {
                toast.error(response.data.message);
            }
        } catch (err) {
            toast.error('Failed to sign in');
        }
    }

    const handleSubmitDocument = async (e) => {
        e.preventDefault()
        try {
            setIsSearching(true);
            setSubmitLabel('Searching...');
            if (searchText) {
                const searchResponse = await axios.post(
                    `${BASE_URL}/athena/search`,
                    {
                        "token": token,
                        "keyword": searchText,
                        "folder": selectedFile ? selectedFile : 'all'
                    }

                );
                if (searchResponse.status) {
                    setSearchResult(searchResponse.data.data);
                    setFilepath(searchResponse.data.filepath);
                }
                else {
                    toast.error(searchResponse.message);
                }
                setSubmitLabel('Search');
                setIsSearching(false);
            }
            else {
                setSubmitLabel('Search');
                setIsSearching(false);
                toast.error('Search text is required');
            }
        } catch (err) {
            console.log(err);
            setSubmitLabel('Search');
            setIsSearching(false);
            toast.error('Try again');
        }
    }
    const handleFileUpload = async (demourl) => {
        const response = await fetch(demourl);
        if (!response.ok) throw new Error("Failed to fetch file");
    
        const arrayBuffer = await response.arrayBuffer();
    
        const result = await Mammoth.convertToHtml({ arrayBuffer });
        setDocxContent(result.value);
        setLoading(true);
        if (containerRef.current) {
          await renderAsync(arrayBuffer, containerRef.current); // Render DOCX content
          setLoading(false);
        }
      };
    
      const highlightKeywords = () => {
        if (!predefinedKeywords || predefinedKeywords.length === 0) return;
    
        const elements = containerRef.current?.querySelectorAll("p, span");
        const regex = new RegExp(`(${predefinedKeywords.join("|")})`, "gi");
    
        elements.forEach((element) => {
          element.innerHTML = element.innerHTML.replace(
            regex,
            (match) => `<span class="highlight">${match}</span>`
          );
        });
      };
    return (
        <>
            {isLogin ? (
                <div
                    className="terms-container"
                    style={{ marginLeft: '-400px', width: '1240px' }}
                >
                    <div className="row">
                        <div className="card ">
                            <h2 className="card-title" style={{ marginTop: "10px", marginBottom: "10px" }}>Search athena</h2>
                            <div className="col-md-12 d-flex1 1justify-content-center">
                                {/* <div className='col-4'> */}

                                <div className="card-body">
                                    <form onSubmit={handleSubmitDocument}>
                                        <div className='row'>
                                            <div className='col-md-8'>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        value={searchText}
                                                        onChange={(e) => setSearchText(e.target.value)}
                                                        required
                                                        placeholder="Enter search text here"
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-2 form-group'>
                                                <select
                                                className='form-control'
                                                onChange={(e) => setSelectedFile(e.target.value)}
                                                >
                                                    <option value="">Select file</option>
                                                    <option value="all">All</option>
                                                    <option value="Athena_Labs_LLC_Puerto_Rico_Corporate_Files">Athena Labs LLC (Puerto Rico) Corporate Files</option>
                                                    <option value="Oraz_Philippines_LLC">Oraz Philippines LLC</option>
                                                    <option value="Series_A">Series A</option>
                                                </select>
                                            </div>
                                            <div className='col-md-2'>
                                                <button type="submit" className="m-0 btn btn-dark btn-sm1" disabled={submitLabel === 'Searching...' ? true : false}>
                                                    {submitLabel}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                {/* </div> */}
                            </div>
                        </div>

                        {isSearching ? (
                            searchResult.length === 0 ? (
                                <div className="col-12 text-center">Searching...</div>
                            ) : (
                                null
                            )
                        ) : (searchResult && searchResult.length) > 0 ? (
                            <div className="card mt-3">
                                <div className="card-body">
                                    <h2 className="card-title p-0 m-0">Result</h2>
                                    <div className="table-responsive">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Filename</th>
                                                    <th>Answer</th>
                                                    <th>Preview</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {searchResult.map((app, index) => (
                                                    <tr key={index}>
                                                        <td>{app.filename}</td>
                                                        <td>{app.answer ? app.answer : 'N/A'}</td>
                                                        <td><button onClick={()=>{
                                                            setDocumentUrl(filepath+'/'+app.filename)
                                                            if(app.filename.includes('docx')){
                                                                handleFileUpload(filepath+'/'+app.filename);
                                                            }
                                                            
                                                            setshowFileOpenerView(true)
                                                        }} className='btn btn-primary btn-sm'>View</button></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : (
                <div className="ac pt-0 text-center">
                    <div className='logo_wrps'>
                    </div>
                    <h2 className="login-heading">Log in</h2>
                    <form onSubmit={handleLogin}>
                        <div className="form-group mb-2">
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                placeholder="Enter password"
                            />
                        </div>
                        <div>
                            <button type="submit" className="auth-btn m-0">
                                Log in
                            </button>
                        </div>
                    </form>
                </div>
            )
            }
            {showFileOpenerView && (
        <>
          <div className="modal  fade show" style={{paddingTop:"6rem"}}>
            <div className="modal-dialog  modal-xl modal-dialog-centered">
              <div className="modal-content border-none p-3" style={{maxWidth:"90%"}}>
                <div className="modal-body " >
                  <div className="container px-3 py-2">
                    <div className="row">
                      <div className="col-8"></div>
                      <div className="col-4 text-right">
                        <button
                          className="btn_close_x p-0 m-0"
                          onClick={() => setshowFileOpenerView(false)}
                          style={{ marginTop: '0px' }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#6b7280"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                          >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12" style={{ textAlign: 'center' }}>
                      {documentUrl && (
                            <div
                            style={{
                                height: "100vh",
                                overflow: "auto",
                                display: "flex",
                                justifyContent: "center",
                            }}
                            >
                            {documentUrl.includes("pdf") && (
                                <Document
                                file={documentUrl}
                                onLoadSuccess={onDocumentLoadSuccess}
                                >
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    // customTextRenderer={(textItem) =>
                                    //     highlightKeyword(textItem.str, currentSearch)
                                    // }
                                    />
                                ))}
                                </Document>
                            )}
                            {documentUrl.includes("docx") && (
                                <div >
                                <div
                                    ref={containerRef}
                                    style={{
                                    
                                    padding: "0",
                                    height: "80vh",
                                    overflow: "auto",
                                    fontFamily: "'Times New Roman', serif",
                                    whiteSpace: "pre-wrap",
                                    }}
                                />
                                </div>
                            )}
                            {documentUrl.includes("xlsx") && (
                                <>
                                <button onClick={()=>{
                                                            window.open(documentUrl,'_blank')
                                                        }} className='btn btn-primary btn-sm'>Download</button>
                                </>
                            )}
                            </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
        </>

    )
}
