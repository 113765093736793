import React, { useState } from 'react'
import axios from 'axios'
import { BASE_URL } from '../../config'
// import '../../styles/Auth.css'
import '../../styles/Terms.css'
import { toast } from 'react-toastify'
// import '../../components/Redline/Redline.css'

export default function RedlineDocument() {
    const [password, setPassword] = useState('');
    const [redlineText, setRedlineText] = useState('');
    const [redlineFilename, setRedlineFilename] = useState('');
    const [isLogin, setIsLogin] = useState(false);
    const [btnText, setBtnText] = useState('Redline Document');

    const handleLogin = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.post(`${BASE_URL}/redline/santiStensul/login`, { password })
            if (response.data.status === 1) {
                toast.success(response.data.message);
                setIsLogin(true)
            } else {
                toast.error(response.data.message);
            }
        } catch (err) {
            toast.error('Failed to sign in');
        }
    }

    const handleSubmitDocument = async (e) => {
        e.preventDefault();
        setBtnText('Processing...');
        try {

            if (redlineFilename) {
                const formData = new FormData()
                formData.append('file', redlineFilename)
                const uploadResponse = await axios.post(
                    `${BASE_URL}/redline/santiStensul/upload-file`,
                    formData,
                    {
                        headers: { 'Content-Type': 'multipart/form-data' },
                    }
                )

                if (uploadResponse.data.status === 1) {
                    const response = await axios.post(
                        `${BASE_URL}/redline/santiStensul/generate`,
                        {
                            text: redlineText,
                            filename: uploadResponse.data.filename,
                            original_filename: uploadResponse.data.original_filename
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            responseType: 'blob',
                        }
                    )

                    toast.success(response.data.message);
                    console.log('response.data: ', response.data);
                    const blob = new Blob([response.data], {
                        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    })
                    const downloadUrl = window.URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = downloadUrl
                    link.setAttribute('download', `redlined-file.docx`)
                    // link.setAttribute('download', `redlined-${uploadResponse.data.original_filename}.docx`)
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    setBtnText('Redline Document');
                } else {
                    toast.error(uploadResponse.data.message);
                    setBtnText('Redline Document');
                }
            }
            else {
                toast.error('Select file');
                setBtnText('Redline Document');
            }
        } catch (err) {
            console.log(err);
            toast.error('Try again');
            setBtnText('Redline Document');
        }
    }

    const handleFileChange = (event) => {
        setRedlineFilename(event.target.files[0])
    }
    return (
        <>
            {isLogin ? (
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <h2 className="card-title" style={{ marginTop: "0px", marginBottom: "50px" }}>Redline Document</h2>
                                    <form onSubmit={handleSubmitDocument}>
                                        <div className="form-group mb-2">
                                            <input
                                                type="file"
                                                required
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                        {/* <div className="form-group mb-2">
                                            <input
                                                type="text"
                                                value={redlineText}
                                                onChange={(e) => setRedlineText(e.target.value)}
                                                required
                                                placeholder="Enter redline text here"
                                            />
                                        </div> */}
                                        <div>
                                            <button type="submit" className="auth-btn m-0" disabled={btnText === 'Processing...' ? true : false}>   
                                                {btnText}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="ac pt-0 text-center">
                    <div className='logo_wrps'>
                    </div>
                    <h2 className="login-heading">Log in</h2>
                    <form onSubmit={handleLogin}>
                        <div className="form-group mb-2">
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                placeholder="Enter password"
                            />
                        </div>
                        <div>
                            <button type="submit" className="auth-btn m-0">
                                Log in
                            </button>
                        </div>
                    </form>
                </div>
            )
            }
        </>

    )
}
